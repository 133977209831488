.custom-select {
  position: relative;
  min-width: 100%;
  width: 100%;

  [class*="select__menu"] {
    width: 100%;
    min-width: 100%;
    position: absolute;
    z-index: 100000;
    background-color: white;
    border-right: 1px solid rgba(0, 0, 0, 0.3);
    border-left: 1px solid rgba(0, 0, 0, 0.3);
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}
