*, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}

.tw-pointer-events-none {
  pointer-events: none;
}

.tw-z-50 {
  z-index: 50;
}

.tw-my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.\!tw-mt-0 {
  margin-top: 0px !important;
}

.tw-mb-1 {
  margin-bottom: 0.25rem;
}

.tw-mb-6 {
  margin-bottom: 1.5rem;
}

.tw-mt-2 {
  margin-top: 0.5rem;
}

.tw-flex {
  display: flex;
}

.tw-grid {
  display: grid;
}

.tw-hidden {
  display: none;
}

.\!tw-h-full {
  height: 100% !important;
}

.tw-h-6 {
  height: 1.5rem;
}

.tw-h-8 {
  height: 2rem;
}

.tw-max-h-\[75vh\] {
  max-height: 75vh;
}

.tw-min-h-\[calc\(100vh-80px\)\] {
  min-height: calc(100vh - 80px);
}

.tw-w-6 {
  width: 1.5rem;
}

.tw-w-\[180px\] {
  width: 180px;
}

.tw-w-\[400px\] {
  width: 400px;
}

.tw-w-full {
  width: 100%;
}

.tw-min-w-\[33\%\] {
  min-width: 33%;
}

.tw-max-w-\[200px\] {
  max-width: 200px;
}

.tw-max-w-\[250px\] {
  max-width: 250px;
}

.tw-max-w-\[300px\] {
  max-width: 300px;
}

.tw-max-w-\[400px\] {
  max-width: 400px;
}

.tw-max-w-full {
  max-width: 100%;
}

.tw-grow {
  flex-grow: 1;
}

.tw-border-spacing-6 {
  --tw-border-spacing-x: 1.5rem;
  --tw-border-spacing-y: 1.5rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.\!tw-cursor-not-allowed {
  cursor: not-allowed !important;
}

.tw-cursor-not-allowed {
  cursor: not-allowed;
}

.tw-cursor-pointer {
  cursor: pointer;
}

.tw-select-none {
  -webkit-user-select: none;
          user-select: none;
}

.tw-grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.tw-grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.tw-grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.tw-grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.tw-grid-cols-\[150px_1fr\] {
  grid-template-columns: 150px 1fr;
}

.tw-grid-cols-\[1fr_40px\] {
  grid-template-columns: 1fr 40px;
}

.tw-flex-col {
  flex-direction: column;
}

.tw-flex-wrap {
  flex-wrap: wrap;
}

.tw-items-start {
  align-items: flex-start;
}

.tw-items-center {
  align-items: center;
}

.tw-items-stretch {
  align-items: stretch;
}

.tw-justify-end {
  justify-content: flex-end;
}

.tw-justify-center {
  justify-content: center;
}

.tw-justify-between {
  justify-content: space-between;
}

.tw-gap-1 {
  gap: 0.25rem;
}

.tw-gap-10 {
  gap: 2.5rem;
}

.tw-gap-2 {
  gap: 0.5rem;
}

.tw-gap-4 {
  gap: 1rem;
}

.tw-gap-8 {
  gap: 2rem;
}

.tw-gap-y-10 {
  row-gap: 2.5rem;
}

.tw-divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.tw-divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-divide-opacity));
}

.tw-overflow-hidden {
  overflow: hidden;
}

.tw-overflow-y-auto {
  overflow-y: auto;
}

.tw-overflow-x-scroll {
  overflow-x: scroll;
}

.tw-text-ellipsis {
  text-overflow: ellipsis;
}

.tw-whitespace-nowrap {
  white-space: nowrap;
}

.tw-break-words {
  overflow-wrap: break-word;
}

.tw-break-all {
  word-break: break-all;
}

.tw-rounded-\[9px\] {
  border-radius: 9px;
}

.tw-rounded-full {
  border-radius: 9999px;
}

.tw-rounded-lg {
  border-radius: 0.5rem;
}

.tw-rounded-xl {
  border-radius: 0.75rem;
}

.tw-rounded-b-xl {
  border-bottom-right-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.tw-border {
  border-width: 1px;
}

.tw-border-b {
  border-bottom-width: 1px;
}

.tw-border-solid {
  border-style: solid;
}

.\!tw-border-transparent {
  border-color: transparent !important;
}

.tw-border-\[\#E1E1E1\] {
  --tw-border-opacity: 1;
  border-color: rgb(225 225 225 / var(--tw-border-opacity));
}

.tw-border-black\/30 {
  border-color: rgb(0 0 0 / 0.3);
}

.tw-border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.tw-border-ulluPrimary {
  --tw-border-opacity: 1;
  border-color: rgb(31 33 35 / var(--tw-border-opacity));
}

.tw-bg-\[\#E9F0FF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(233 240 255 / var(--tw-bg-opacity));
}

.tw-bg-\[\#F5F8FF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(245 248 255 / var(--tw-bg-opacity));
}

.tw-bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.tw-bg-transparent {
  background-color: transparent;
}

.tw-bg-ulluPrimary {
  --tw-bg-opacity: 1;
  background-color: rgb(31 33 35 / var(--tw-bg-opacity));
}

.tw-bg-ulluSecondary {
  --tw-bg-opacity: 1;
  background-color: rgb(30 72 247 / var(--tw-bg-opacity));
}

.tw-bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.tw-p-2 {
  padding: 0.5rem;
}

.tw-p-4 {
  padding: 1rem;
}

.tw-p-5 {
  padding: 1.25rem;
}

.tw-p-8 {
  padding: 2rem;
}

.tw-px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.tw-px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.tw-py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.tw-py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.tw-py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.tw-py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.tw-pb-10 {
  padding-bottom: 2.5rem;
}

.tw-pb-2 {
  padding-bottom: 0.5rem;
}

.tw-pb-4 {
  padding-bottom: 1rem;
}

.tw-pb-5 {
  padding-bottom: 1.25rem;
}

.tw-pb-6 {
  padding-bottom: 1.5rem;
}

.tw-pb-8 {
  padding-bottom: 2rem;
}

.tw-pl-2 {
  padding-left: 0.5rem;
}

.tw-pl-6 {
  padding-left: 1.5rem;
}

.tw-pl-8 {
  padding-left: 2rem;
}

.tw-pr-10 {
  padding-right: 2.5rem;
}

.tw-pr-4 {
  padding-right: 1rem;
}

.tw-pt-4 {
  padding-top: 1rem;
}

.tw-pt-8 {
  padding-top: 2rem;
}

.tw-text-left {
  text-align: left;
}

.tw-text-center {
  text-align: center;
}

.tw-text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.tw-text-\[14px\] {
  font-size: 14px;
}

.tw-text-\[24px\] {
  font-size: 24px;
}

.tw-text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.tw-text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.tw-text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.tw-text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.tw-font-bold {
  font-weight: 700;
}

.tw-font-extrabold {
  font-weight: 800;
}

.tw-font-medium {
  font-weight: 500;
}

.tw-font-semibold {
  font-weight: 600;
}

.tw-uppercase {
  text-transform: uppercase;
}

.tw-capitalize {
  text-transform: capitalize;
}

.tw-italic {
  font-style: italic;
}

.tw-leading-6 {
  line-height: 1.5rem;
}

.tw-leading-\[18px\] {
  line-height: 18px;
}

.tw-tracking-wider {
  letter-spacing: 0.05em;
}

.tw-text-\[\#61626B\] {
  --tw-text-opacity: 1;
  color: rgb(97 98 107 / var(--tw-text-opacity));
}

.tw-text-\[\#959595\] {
  --tw-text-opacity: 1;
  color: rgb(149 149 149 / var(--tw-text-opacity));
}

.tw-text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.tw-text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}

.tw-text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.tw-text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

.tw-text-ulluPrimary {
  --tw-text-opacity: 1;
  color: rgb(31 33 35 / var(--tw-text-opacity));
}

.tw-text-ulluSecondary {
  --tw-text-opacity: 1;
  color: rgb(30 72 247 / var(--tw-text-opacity));
}

.tw-text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.tw-underline {
  text-decoration-line: underline;
}

.tw-line-through {
  text-decoration-line: line-through;
}

.tw-underline-offset-2 {
  text-underline-offset: 2px;
}

.tw-opacity-50 {
  opacity: 0.5;
}

.tw-opacity-60 {
  opacity: 0.6;
}

.\!tw-shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1) !important;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.\!tw-shadow-none {
  --tw-shadow: 0 0 #0000 !important;
  --tw-shadow-colored: 0 0 #0000 !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.tw-shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.\!tw-outline-none {
  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
}

.tw-grayscale {
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hover\:tw-scale-110:hover {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:tw-bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.hover\:tw-text-blue-600:hover {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

@media (min-width: 768px) {
  .md\:tw-grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media (min-width: 1024px) {
  .lg\:tw-grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}
